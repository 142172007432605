<template>
  <div class="company-bg">
    <div class="container">
      <div class="banner">
        <div class="banner-title">
          <span>
            安全文化专家<br />
            答疑解惑</span>
        </div>
        <img src="../../assets/company/banner-eq.png"
             class="banner-eq" />
      </div>
      <div class="content-wrap">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="/home">首页</a></li>
            <li class="breadcrumb-item"><a href="/eqlist">答疑解惑</a></li>
            <li class="breadcrumb-item active"
                aria-current="page">详情</li>
          </ol>
        </nav>
        <div class="eq-list">
          <div class="eq-item"
               bindtap="toEqDetail">
            <div class="eq-title">
              {{info.question}}
            </div>
            <div class="eq-intro">
              <div class="eq-class">{{info.industry}}</div>
              <div class="eq-expert">
                <div class="eq-time">提问于 {{info.created_at}}</div>
              </div>
            </div>
            <div class="eq-des">
              {{info.question_detail}}
            </div>
            <i class="iconfont icon-canyuhuati"></i>
          </div>
          <div class="eq-item">
            <div class="eq-intro">
              <div class="eq-expert">
                <div class="expert-name">{{ info.expert_name }} </div>
              </div>
              <div class="eq-time">答复于 {{ info.updated_at }}</div>
            </div>
            <div class="eq-des">
              {{ decodeContent(info.answer) }}
            </div>
          </div>
        </div>
        <!-- 公共收藏 -->
        <div class="fav"
             @click="toFavourite">
          <div class="fav-shape">
            <i :class="['iconfont', info.is_favourite == 1 ? 'icon-favourite_selected' : 'icon-favourite_normal']"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  fetchQuestionDetail,
  questionFavourite
} from "@/network/API.js";
export default {
  data() {
    return {
      info: {}
    }
  },
  async created() {
    if (this.$route.params.id) {
      let info_id = this.$route.params.id
      this.info = await fetchQuestionDetail(info_id)
    }
  },
  methods: {
    async toFavourite() {
      let loader = this.$loading.show()
      let res = await questionFavourite({
        id: this.info.id
      })
      loader.hide()
      if (res.is_favourite == 1) {
        this.$toast('收藏成功')
      } else {
        this.$toast('取消收藏')
      }
      this.info.is_favourite = res.is_favourite
    }
  }
};
</script>
<style scoped>
.banner-eq {
  position: absolute;
  right: 0px;
  top: 40px;
  width: 350px;
  height: 350px;
  display: block;
}

.expert-name {
  font-size: 21px;
}
.expert-name span {
  font-size: 16px;
  color: #666;
}
</style>
